td.no-padding {
    border: none !important;
    padding: 0 !important;
}

.div-table.inventory {
    display: table;
    width: 170px;
}

.div-table.number {
    display: table;
    width: 80px
}

.div-table-row {
    display: table-row;
}

.div-table-col.r1 {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ddd;
    border-top: 0px;
    border-bottom: 0px;
    border-left: 0px
}

.div-table-col.r2 {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ddd;
    border-left: 0px
}

.div-table-col.r3 {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ddd;
    border-top: 0px;
    border-left: 0px
}

.div-table-col.header {
    font-weight: bold;
    background-color: #f2f2f2;
}