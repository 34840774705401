/* RawMaterialList.css */
.rawMaterialContainer {
    padding: 20px;
}

.searchInput {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.materialTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.materialTable th,
.materialTable td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}

.zoom-9{
    zoom: 0.9;
}