/* a {
    text-decoration: unset;
  } */
  
  .ag-root-wrapper {
    border: 0;
  }
  
  .ag-row {
    border: 0;
  }
  
  .ag-row-even {
    background-color: #f2f2f2;
  }
  
  .ag-row-odd {
    background-color: #ffff;
  }
  
  .ag-header {
    background-color: white;
    border: 0;
  }
  
  .ag-header-row {
    color: #15364a !important;
  }
  
  .ag-center-cols-container {
    width: 100%;
  }
  
  /* .ag-body-horizontal-scroll {
      visibility: hidden;
  } */
  
  .ag-ltr .ag-cell {
    font-weight: 400;
    font-size: 12px !important;
    color: #343d42 !important;
    font-family: 'DIN' !important;
  }
  
  .ag-paging-panel {
    font-size: 10px;
  }
  
  .MuiBackdrop-root.MuiBackdrop-invisible.css-h9f53t-MuiBackdrop-root-MuiModal-backdrop {
    opacity: 0 !important;
  }
  
  fieldset {
    border-radius: 0px !important;
  }
  
  .ag-cell-wrap-text {
    white-space: unset;
  }
      